export const namespaced = true;

export const state = {
    followupNote: {},
};

export const getters = {};

export const mutations = {
    setFollowupNote(state, followupNote) {
        state.followupNote = followupNote;
    },
};

export const actions = {
    async getFollowUpNotes(ctx) {
        const axios = ctx.rootGetters.axios;
        const resp = await axios.get(`/v1/clients/follow-up-notes`).then(r => r);
        const data = resp.data.data;
        ctx.commit("setFollowupNote", data);
        return resp.data;
    },
};
