import Vue from "vue"

export const namespaced = true
export const state = {
  // 1
  generalData: {
    firstName: null,
    middleName: null,
    lastName: null,
    gender: null,
    age: null,
    birthDate: null,
    address: null,
    city: null,
    province: null,
    cities: null,
    zipCode: null,
    phone: null,
    mainTarget: null,
    expectedDiet: null,
    hasScales: null,
    currentCondition: null,
  },
  // 2
  anthropometryData: {
    weight: null,
    height: null,
    waistSize: null,
    fat: null,
    muscleMass: null,
    bodyWater: null,
    visceralFat: null,
    boneMass: null,
    basalMetabolicRate: null,
  },
  // 3
  dietAndEatingHistory: {
    foodPreference: null,
    breakfastHabits: null,
    breakfastFoodType: null,
    mainFoodConsumption: null,
    snackConsumption: null,
    foodConsumptionType: null,
    dislikeFood: null,
    favoriteFood: null,
    expectedBreakfast: null,
    expectedLunchDinner: null,
    foodAllergies: null,
    vegetablesConsumption: null,
    fruitConsumption: null,
    beveragesComsumption: null,
    friedSnackConsumption: null,
    saltySnackConsumption: null,
    personServeFood: null,
    milkFoodAndDrinkConsumption: null,
    lifestyle: null,
  },
  // 4
  dailyActivities: {
    activeLevel: null,
    sportType: [],
    weekdaysTypicalActivity: null,
    weekendTypicalActivity: null,
    averageSleepHours: null,
    bedTime: null,
  },
  // 5
  foodRecords: [
    // {
    //   category: "breakfast",
    //   stapleFood: "",
    //   animalSideDish: "",
    //   vegetableSideDish: "",
    //   vegetable: "",
    //   fruit: "",
    //   drink: "",
    //   photoUrl: "http://some.food.url/",
    // },
    // {
    //   category: "morning_snack",
    //   snack: "",
    //   photoUrl: "http://some.photo.url",
    // },
    // {
    //   category: "lunch",
    //   stapleFood: "nasi merah",
    //   animalSideDish: "dada ayam",
    //   vegetableSideDish: "-",
    //   vegetable: "brokoli",
    //   fruit: "jeruk",
    //   drink: "es teh",
    //   photoUrl: null,
    // },
    // {
    //   category: "afternoon_snack",
    //   snack: "bon cabe",
    //   photoUrl: null,
    // },
    // {
    //   category: "dinner",
    //   stapleFood: "mashed potato",
    //   animalSideDish: "steak",
    //   vegetableSideDish: "truffle",
    //   vegetable: "buncis",
    //   fruit: "anggur hijau",
    //   drink: "jus lemon",
    //   photoUrl: null,
    // },
  ],
  // 6
  physicalActivityAbilities: {
    hasDoctorDiagnose: null,
    hasChestPain: null,
    hasLostBalance: null,
    hasBoneProblem: null,
    isOnTreatment: null,
    hasOtherCondition: null,
  },
  // 7
  medicalHistory: {
    job: null,
    lastEducation: null,
    drugsConsumption: null,
    specialMedicine: null,
    supplementConsumption: null,
    otherDisease: null,
    communicationIntensity: null,
    expectNutritionist: null,
    expectProgram: null,
    mentalDisorder: [],
    disease: [],
    symptom: [],
    currentBehavior: [],
  },
  // ---
  questions: [],
}

export const getters = {
  questionFor(state) {
    return (category) => {
      return state.questions.filter((it) => it.category === category)
    }
  },
}

export const mutations = {
  setGeneralData(state, payload) {
    state.generalData = payload
  },
  setAnthropometryData(state, payload) {
    state.anthropometryData = payload
  },
  setDietAndEatingHistory(state, payload) {
    state.dietAndEatingHistory = payload
  },
  setDailyActivities(state, payload) {
    state.dailyActivities = payload
  },
  setPhysicalActivityAbilities(state, payload) {
    state.physicalActivityAbilities = payload
  },
  setMedicalHistory(state, payload) {
    state.medicalHistory = payload
  },
  setFoodRecords(state, payload) {
    state.foodRecords = payload
  },
  setQuestion(state, question) {
    let index = state.questions.findIndex((it) => it.id === question.id)

    if (index !== -1) {
      Vue.set(state.questions, index, question)
    } else {
      state.questions.push(question)
    }
  },
  setAnswer(state, answer) {
    let index = state.questions.findIndex((it) => it.id === answer.questionId)

    if (index !== -1) {
      Vue.set(state.questions, index, {
        ...state.questions[index],
        answer: answer.answer,
      })
    }
  },
}
export const actions = {
  // Draft / save to backend temporarily
  async setGeneralData({ commit, rootGetters }, payload) {
    let axios = rootGetters.axios
    let { programId, answers, value } = payload

    commit("setGeneralData", value)
    for (let answer of answers) {
      commit("setAnswer", answer)
    }

    return axios
      .post(`/v1/clients/questionnaire/${programId}`, {
        isDraft: true,
        answers,
        generalData: value,
      })
      .then((r) => r.data.data)
  },
  async setAnthropometryData({ commit, rootGetters }, payload) {
    let axios = rootGetters.axios
    let { programId, answers, value } = payload

    commit("setAnthropometryData", value)
    for (let answer of answers) {
      commit("setAnswer", answer)
    }

    return axios
      .post(`/v1/clients/questionnaire/${programId}`, {
        isDraft: true,
        answers: answers,
        anthropometryData: value,
      })
      .then((r) => r.data.data)
  },
  async setDietAndEatingHistory({ commit, rootGetters }, payload) {
    let axios = rootGetters.axios
    let { programId, value, answers } = payload

    commit("setDietAndEatingHistory", value)
    for (let answer of answers) {
      commit("setAnswer", answer)
    }

    return axios
      .post(`/v1/clients/questionnaire/${programId}`, {
        isDraft: true,
        answers: answers,
        dietAndEatingHistory: value,
      })
      .then((r) => r.data.data)
  },
  async setDailyActivities({ commit, rootGetters }, payload) {
    let axios = rootGetters.axios
    let { programId, value, answers, isDraft } = payload

    commit("setDailyActivities", value)
    for (let answer of answers) {
      commit("setAnswer", answer)
    }

    return axios
      .post(`/v1/clients/questionnaire/${programId}`, {
        isDraft,
        dailyActivities: value,
        answers,
      })
      .then((r) => r.data.data)
  },
  async setPhysicalActivityAbilities({ commit, rootGetters }, payload) {
    let axios = rootGetters.axios
    let { programId, answers, value } = payload

    commit("setPhysicalActivityAbilities", value)
    for (let answer of answers) {
      commit("setAnswer", answer)
    }

    return axios
      .post(`/v1/clients/questionnaire/${programId}`, {
        isDraft: true,
        physicalActivityAbilities: value,
        answers,
      })
      .then((r) => r.data.data)
  },
  async setMedicalHistory({ commit, rootGetters }, payload) {
    let axios = rootGetters.axios
    let { programId, answers, value } = payload

    commit("setMedicalHistory", value)
    for (let answer of answers) {
      commit("setAnswer", answer)
    }

    return axios
      .post(`/v1/clients/questionnaire/${programId}`, {
        isDraft: true,
        answers,
        medicalHistory: value,
      })
      .then((r) => r.data.data)
  },
  async setFoodRecords({ commit, rootGetters }, payload) {
    let axios = rootGetters.axios
    let { programId, value, answers } = payload

    commit("setFoodRecords", value)
    for (let answer of answers) {
      commit("setAnswer", answer)
    }

    return axios
      .post(`/v1/clients/questionnaire/${programId}`, {
        isDraft: true,
        foodRecords: value,
        answers,
      })
      .then((r) => r.data.data)
  },
  async getByLatestProgram(ctx, clientId) {
    let axios = ctx.rootGetters.axios
    return axios
      .get(`/v1/nutritionist/clients/${clientId}/latest-program`)
      .then((r) => r.data.data.id)
      .then((id) => axios.get(`/v1/nutritionist/quizioner/${id}`))
      .then((r) => r.data.data)
  },
  get(ctx, programId) {
    let axios = ctx.rootGetters.axios
    return axios
      .get(`/v1/clients/quizioner/${programId}`)
      .then((r) => r.data.data)
      .then((r) => {
        ctx.commit("setGeneralData", r.generalData)
        ctx.commit("setAnthropometryData", r.anthropometryData)
        ctx.commit("setDietAndEatingHistory", r.dietAndEatingHistory)
        // ctx.commit("setDailyActivities", r.dailyActivities);
        ctx.commit("setPhysicalActivityAbilities", r.physicalActivityAbilities)
        ctx.commit("setMedicalHistory", r.medicalHistory)
        // ctx.commit("setFoodRecords", r.foodRecords);
        return r
      })
  },
  post(ctx, programId) {
    let axios = ctx.rootGetters.axios

    let foodRecords = ctx.state.foodRecords
    if (
      !Array.isArray(foodRecords) &&
      Object.getPrototypeOf(foodRecords) === Object.prototype
    ) {
      foodRecords = Object.values(foodRecords)
      ctx.state.foodRecords = foodRecords
    }

    return axios
      .post(`/v1/clients/quizioner/${programId}`, ctx.state)
      .then((response) => {
        return response.data
      })
  },
  clear(ctx) {
    ctx.commit("setGeneralData", state.generalData)
    ctx.commit("setAnthropometryData", state.anthropometryData)
    ctx.commit("setDietAndEatingHistory", state.dietAndEatingHistory)
    ctx.commit("setDailyActivities", state.dailyActivities)
    ctx.commit("setPhysicalActivityAbilities", state.physicalActivityAbilities)
    ctx.commit("setMedicalHistory", state.medicalHistory)
    ctx.commit("setFoodRecords", state.foodRecords)
  },
  getForNutri(ctx, programId) {
    let axios = ctx.rootGetters.axios
    return axios
      .get(`/v1/nutritionist/quizioner/${programId}`)
      .then((r) => r.data.data)
      .then((r) => {
        ctx.commit("setGeneralData", r.generalData)
        ctx.commit("setAnthropometryData", r.anthropometryData)
        ctx.commit("setDietAndEatingHistory", r.dietAndEatingHistory)
        ctx.commit("setDailyActivities", r.dailyActivities)
        ctx.commit("setPhysicalActivityAbilities", r.physicalActivityAbilities)
        ctx.commit("setMedicalHistory", r.medicalHistory)
        ctx.commit("setFoodRecords", r.foodRecords)
        return r
      })
  },
  async list(ctx) {
    let axios = ctx.rootGetters.axios

    return axios.get(`/v1/clients/quizioner`).then((r) => r.data)
  },
  async getV2ClientForCategory({ commit, rootGetters, dispatch }, category) {
    let axios = rootGetters.axios
    let resp = await axios
      .get(`/v1/clients/questionnaire-questions?category=${category}`)
      .then((r) => r.data.data)

    for (let item of resp) {
      commit("setQuestion", item)
    }

    // await dispatch("getV2ClientDraft")

    return resp
  },
  async getV2Client(ctx, programId) {
    let filters = [
      "informed_consent",
      "general_data",
      "anthropometry_data",
      "diet_and_eating_history",
      "daily_activities",
      "food_records",
      "physical_activity_abilities",
      "medical_history",
    ]

    let axios = ctx.rootGetters.axios
    let promises = []

    for (let category of filters) {
      let promise = axios
        .get(`/v1/clients/questionnaire-questions?category=${category}`)
        .then((r) => r.data.data)
      promises.push(promise)
    }

    promises = await Promise.all(promises)
    promises = promises.flatMap((it) => it)
    for (let item of promises) {
      ctx.commit("setQuestion", item)
    }

    await ctx.dispatch("getV2ClientAnswer", programId)

    return promises
  },
  async getV2NutriAnswers(ctx, programId) {
    const axios = ctx.rootGetters.axios
    let data = await axios
      .get(`/v1/nutritionist/quizioner/${programId}`)
      .then((r) => r.data.data)

    for (let answer of data.answers ?? []) {
      ctx.commit("setAnswer", answer)
    }

    // console.log("answers", data);
    // console.log("generalData", data.generalData);

    ctx.commit("setAnthropometryData", data.anthropometryData)
    ctx.commit("setDailyActivities", data.dailyActivities)
    ctx.commit("setDietAndEatingHistory", data.dietAndEatingHistory)
    ctx.commit("setFoodRecords", data.foodRecords)
    ctx.commit("setGeneralData", data.generalData)
    ctx.commit("setMedicalHistory", data.medicalHistory)
    ctx.commit("setPhysicalActivityAbilities", data.physicalActivityAbilities)

    return data
  },
  async _getV2ClientAnswers(ctx, { prefix = "clients", programId, isDraft }) {
    let axios = ctx.rootGetters.axios
    let url = `/v1/${prefix}/questionnaire/${programId}`
    if (isDraft) {
      url += `?isDraft=true`
    }

    let data = await axios.get(url).then((r) => r.data.data)

    for (let answer of data.answers ?? []) {
      ctx.commit("setAnswer", answer)
    }

    // console.log("answers", data);
    // console.log("generalData", data.generalData);

    ctx.commit("setAnthropometryData", data.anthropometryData)
    ctx.commit("setDailyActivities", data.dailyActivities)
    ctx.commit("setDietAndEatingHistory", data.dietAndEatingHistory)
    ctx.commit("setFoodRecords", data.foodRecords)
    ctx.commit("setGeneralData", data.generalData)
    ctx.commit("setMedicalHistory", data.medicalHistory)
    ctx.commit("setPhysicalActivityAbilities", data.physicalActivityAbilities)

    return data
  },
  async getV2ClientAnswer({ dispatch }, programId) {
    return dispatch("_getV2ClientAnswers", { programId, isDraft: false })
  },
  async getV2ClientDraft({ dispatch }, programId) {
    return dispatch("_getV2ClientAnswers", { programId, isDraft: true })
  },
  async getV2SuperadminAnswer({ dispatch }, { programId }) {
    return dispatch("_getV2ClientAnswers", {
      programId,
      prefix: "super-admin",
      isDraft: false,
    })
  },
  async postV2({ state, dispatch, rootGetters }, programId) {
    let axios = rootGetters.axios

    await dispatch("getV2Client", programId)
    await dispatch("getV2ClientDraft", programId)

    let questions = state.questions
    let generalData = state.generalData
    let anthropometryData = state.anthropometryData
    let dailyActivities = state.dailyActivities
    let dietAndEatingHistory = state.dietAndEatingHistory
    let foodRecords = state.foodRecords
    let medicalHistory = state.medicalHistory
    let physicalActivityAbilities = state.physicalActivityAbilities

    return axios
      .post(`/v1/clients/questionnaire/${programId}`, {
        isDraft: false,
        answers: questions.map((it) => ({
          questionId: it.id,
          answer: it.answer,
          question: it.question,
        })),
        generalData,
        anthropometryData,
        dailyActivities,
        dietAndEatingHistory,
        foodRecords,
        medicalHistory,
        physicalActivityAbilities,
      })
      .then((r) => r.data.data)
  },
}
