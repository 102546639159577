<template>
  <c-box bg="white">
    <portal-target name="header" />
    <portal-target name="main" />
    <router-view />
    <!--<portal-target name="footer"/>-->
  </c-box>
</template>

<script>
import { Fragment } from "vue-fragment";

export default {
  name: "App",
  components: { Fragment },
};
</script>

<style>
body {
  padding-bottom: 0 !important;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
[id*="modal-portal"] {
  position: fixed;
  z-index: 9999;
}
</style>
