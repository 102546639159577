//  custom-theme.js

export default {
  breakpoints: ["30em", "48em", "62em", "80em"],

  fonts: {
    heading: '"Montserrat", sans-serif',
    body: "Roboto, sans-serif",
    mono: "Menlo, monospace",
  },
  colors: {
    brand: {
      400: "#008C81",
      800: "#00A68C",
      500: "#008C81",
      900: "#008C81",
    },
    secondary: {
      800: "#F4CC46",
      900: "#F1E456",
    },
    black: {
      900: "#111111",
    },
    superDarkGray: {
      900: "#333333",
    },
    darkGray: {
      900: "#555555",
    },
    gray: {
      900: "#888888",
    },
    lightGray: {
      900: "#C4C4C4",
    },
    superLightGray: {
      900: "#F2F2F2",
    },
    red: {
      900: "#EA4335",
    },
    blue: {
      900: "#0075E1",
    },
  },
};
