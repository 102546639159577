import Vue from "vue"
import Vuex from "vuex"
import axios from "axios"
import VuexPersistence from "vuex-persist"

import * as auth from "./auth"
import * as cart from "./cart"
import * as notification from "./notification"
import * as profile from "./profile"
import * as dataDiet from "./data-diet"
import * as mealPlan from "./meal-plan"
import * as profileProgram from "./profile-program"
import * as profilePembelian from "./profile-pembelian"
// import * as rencanaMakan from './rencana-makan';
import * as clients from "./clients"
import * as glossary from "./glossary"
import * as kuisioner from "./kuisioner"
import * as contentChallenge from "./content-challenge"
import * as localConfig from "./local-config"
import * as general from "./general"
import * as clientProgressTracker from "./client/progress-tracker"
import * as followUpNotes from "./client/follow-up-notes.js"

// nutritionist
import * as nutriProgressTracker from "./nutri/progress-tracker"
import * as nutriFollowUpNotes from "./nutri/follow-up-notes.js"
import * as nutriAdimeNote from "./nutri/adime-notes.js"

// superadmin
import * as suNutritionists from "./superadmin/nutritionists"
import * as suAdmins from "./superadmin/admins"
import * as suManagementClient from "./superadmin/management-client"
import * as suManagementProgram from "./superadmin/diet-program"
import * as suDashboard from "./superadmin/dashboard"
import * as suKuisioner from "./superadmin/kuisioner"
import * as suManagementNotifikasi from "./superadmin/notifikasi"
import * as suGlossary from "./superadmin/glossary"
import * as suCoupons from "./superadmin/coupons"
import * as suLogActivity from "./superadmin/log-activity"
import * as suProgressTracker from "./superadmin/progress-tracker"
import * as suFollowUpNotes from "./superadmin/follow-up-notes"

// role admin
import * as admDashboard from "./admin/dashboard"
import * as admManagementProgram from "./admin/diet-program"
import * as admContentChallenge from "./admin/content-challenge"
import * as admNutritionists from "./admin/nutritionists"
import * as admKuisioner from "./admin/kuisioner"
import * as admCoupons from "./admin/coupons"
import * as admClients from "./admin/clients"
import * as admLogActivity from "./admin/log-activity"
import * as admNotifikasi from "./admin/notifikasi"
import * as admGlossary from "./admin/glossary"
import * as admProgressTracker from "./admin/progress-tracker"
import * as admFollowUpNotes from "./admin/follow-up-notes"


import { createVuexHelpers } from "vue2-helpers"

const persistence = new VuexPersistence({
  key: "dietela-vuex-store",
  reducer(s) {
    return { auth: s.auth, localConfig: s.localConfig }
  },
})

Vue.use(Vuex)

const vuexHelpers = createVuexHelpers()
export const useState = vuexHelpers.useState
export const useActions = vuexHelpers.useActions

let isRefreshingToken = false
let pendingRequest = null

function refreshToken(store) {
  if (isRefreshingToken && pendingRequest != null) return pendingRequest

  isRefreshingToken = true
  pendingRequest = store.dispatch("auth/refreshToken").then((it) => {
    isRefreshingToken = false
    return it
  })

  return pendingRequest
}
window.invalidateToken = () => {
  let data = JSON.parse(localStorage.getItem("dietela-vuex-store"))
  data.auth.token += "--123"
  data.auth.refreshToken += "--123"
  localStorage.setItem("dietela-vuex-store", JSON.stringify(data))
}

export const store = new Vuex.Store({
  plugins: [persistence.plugin],
  state: {},
  getters: {
    axios(state) {
      /** @type {import('axios').AxiosRequestConfig} */
      let config = {
        // baseURL: "http://localhost:3000",
        baseURL: "https://api.dietela.id",
      }

      if (state.auth.token != null) {
        config.headers ??= {}
        config.headers["Authorization"] = `Bearer ${state.auth.token}`
      }

      let client = axios.create(config)

      client.interceptors.response.use(undefined, async (err) => {
        let code = err.response?.status
        let message = err.response?.data?.message ?? ""
        let url = err.request?.responseURL ?? ""
        message = message.toLowerCase()

        // if (code === 403 && message.includes("forbidden")) {
        //   location.replace("#/");
        //   return;
        // }

        if (
          message.includes("invalid token") ||
          message.includes("token invalid") ||
          (url.includes("refresh-token") && message.includes("expired")) ||
          (code === 403 && message.includes("already logout"))
        ) {
          store.commit("auth/setUser", null)
          store.commit("auth/setToken", null)
          store.commit("auth/setRefreshToken", null)

          // location.replace(`#/login?next=${location.hash.slice(1)}`);
          let hashes = location.hash.slice(1)
          if (hashes.includes('next="')) {
            hashes = hashes.replace(/next="(.*?)"/, `next=${hashes}`)
          } else {
            hashes = `next=${hashes}`
          }
          location.replace(`#/login?${hashes}`)
          return
        }

        if (
          message.includes("jwt") ||
          message.includes("invalid signature") ||
          message.includes("expired") ||
          message.includes("token")
        ) {
          return refreshToken(store).then((token) => {
            err.config.headers["Authorization"] = `Bearer ${token}`
            return client.request(err.config).catch(() => {
              store.commit("auth/setUser", null)
              store.commit("auth/setToken", null)
              store.commit("auth/setRefreshToken", null)

              location.replace(`#/login`)
            })
          })
        }

        return Promise.reject(err)
      })

      return client
    },
    customAxiosCMS() {
      /** @type {import('axios').AxiosRequestConfig} */
      let config = {
        baseURL: "https://dietela.cms.project.skyshi.io",
      }

      let cms = axios.create(config)

      cms.interceptors.response.use(undefined, async (err) => {
        let message = err.response?.data?.message ?? ""

        return Promise.reject(message)
      })

      return cms
    },
  },
  modules: {
    auth,
    cart,
    notification,
    profile,
    dataDiet,
    profileProgram,
    profilePembelian,
    mealPlan,
    // rencanaMakan,
    clients,
    glossary,
    kuisioner,
    contentChallenge,
    localConfig,
    general,
    clientProgressTracker,
    followUpNotes,
    //
    suNutritionists,
    suAdmins,
    suManagementClient,
    suManagementProgram,
    suDashboard,
    suKuisioner,
    suManagementNotifikasi,
    suCoupons,
    suGlossary,
    suLogActivity,
    suProgressTracker,
    suFollowUpNotes,

    // role admin
    admDashboard,
    admManagementProgram,
    admContentChallenge,
    admNutritionists,
    admKuisioner,
    admCoupons,
    admClients,
    admLogActivity,
    admNotifikasi,
    admGlossary,
    admProgressTracker,
    admFollowUpNotes,

    // role nutritionist
    nutriProgressTracker,
    nutriFollowUpNotes,
    nutriAdimeNote,
  },
})
