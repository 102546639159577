export const namespaced = true;

export const state = {
    historyFollowupNotes: [],
    reminderFollowupNotes: {},
    followupNote: {},
};

export const getters = {};

export const mutations = {
    setHistory(state, histories) {
        state.historyFollowupNotes = histories;
    },
    setReminder(state, reminder) {
        state.reminderFollowupNotes = reminder;
    },
    setFollowupNote(state, followupNote) {
        state.followupNote = followupNote;
    },
};

export const actions = {
    async getFollowUpNotesByClientId(ctx, clientId) {
        const axios = ctx.rootGetters.axios;
        const resp = await axios.get(`/v1/nutritionist/clients/${clientId}/follow-up-notes`).then(r => r.data);
        const data = resp.data;
        ctx.commit("setHistory", data);
        return data;
    },
    async getFollowUpNotesReminder(ctx, clientId) {
        const axios = ctx.rootGetters.axios;
        const resp = await axios.get(`/v1/nutritionist/clients/${clientId}/follow-up-notes-reminder`).then(r => r.data);
        const data = resp.data;
        ctx.commit("setReminder", data);
        return data;
    },
    async getFollowUpNotesById(ctx, id) {
        const axios = ctx.rootGetters.axios;
        const resp = await axios.get(`/v1/nutritionist/follow-up-notes/${id}`).then(r => r.data);
        const data = resp.data;
        ctx.commit("setFollowupNote", data);
        return data;
    },
    setFollowUpNotesByClientId(ctx, { clientId, params }) {
        const axios = ctx.rootGetters.axios;
        return axios
            .post(`/v1/nutritionist/clients/${clientId}/follow-up-notes`, params)
            .then((it) => it.data.data)
            .then((it) => {
                return it;
            })
            .catch((err) => {
                throw err.response;
            });
    },
};
