import Vue from "vue"
import Chakra, { CThemeProvider, CReset } from "@chakra-ui/vue"
import VueRouter from "vue-router"
import VTooltipPlugin from "v-tooltip"
import VCalendar from "v-calendar"
import PortalVue from "portal-vue"
import "./assets/globals.css"
import App from "./App.vue"
import Routes from "./routes"
import customTheme from "./custom-theme.js"
import { store } from "@/stores"
import _ from "lodash"
import Vuelidate from "vuelidate"
import VueCompositionApi, {
  createApp,
  defineComponent,
} from "@vue/composition-api"
import VueYouTubeEmbed from "vue-youtube-embed"
import { formatDate } from "@/utils/format-date"
import VueMeta from "vue-meta"
import wysiwyg from "vue-wysiwyg"
import "vue-wysiwyg/dist/vueWysiwyg.css"
import { removeNull } from "@/utils/filters"
import "./registerServiceWorker"

import OneSignalVue from "onesignal-vue"

window.__ = _

// console.log(Vue.config)
// Vue.config.errorHandler = (err, vm, info) => {
//   console.log("Something", err, info)
//   vm.$errorToast({
//     message: err.message,
//   })
// }

Vue.use(VueCompositionApi)
Vue.use(VTooltipPlugin)
Vue.use(VCalendar)
Vue.use(PortalVue)
Vue.use(Chakra, {
  extendTheme: customTheme,
})
Vue.use(VueRouter)
Vue.use(Vuelidate)
Vue.use(VueYouTubeEmbed)
Vue.use(VueMeta)
Vue.use(wysiwyg, { hideModules: { image: true } })

if (process.env.NODE_ENV === 'production') {
  Vue.use(OneSignalVue)
}

const router = new VueRouter({
  scrollBehavior: function (to) {
    if (to.hash) {
      return { selector: to.hash }
      //Or for Vue 3:
      //return {el: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: Routes,
})

Vue.filter("formatDate", formatDate)
Vue.filter("satuanMakanan", (value) => {
  switch (value) {
    case "sendok_makan":
      return "sdm"
    case "porsi":
    default:
      return value
  }
})
Vue.filter("toFixed", (value, digits = 2) => {
  return parseFloat(value).toFixed(digits)
})
Vue.filter("formatNull", (value) => value ?? "-")
Vue.filter("removeNull", removeNull)
Vue.filter("parseFloat", it => parseFloat(it))
Vue.mixin({
  computed: {
    axios() {
      return this.$store.getters.axios
    },
    isDevelopment() {
      return process.env.NODE_ENV === "development"
    },
    programId: {
      get() {
        return this.$route.params.programId ?? this.$route.query.programId
      },
      set(v) {
        this.$router.replace({
          ...this.$router,
          query: { programId: v },
        })
      },
    },
    clientId() {
      if (this.$route.params.clientId != null) {
        return this.$route.params.clientId
      } else {
        let isClient = store.state.auth.user?.role === "client"
        let userId = store.state.auth.user?.id
        return isClient ? userId : null
      }
    },
    ["auth::role"]() {
      return this.$store.state.auth?.user?.role
    },
    isClient() {
      return this["auth::role"] === "client"
    },
  },
  methods: {
    $successToast({ message, title = "Success" }) {
      this.$toast({
        title: title,
        description: message,
        status: "success",
        variant: "subtle",
        position: "bottom-right",
      })
    },
    $errorToast({ message, title = "Failed" } = {}) {
      this.$toast({
        title: title,
        description: message,
        status: "error",
        variant: "subtle",
        position: "bottom-right",
      })
    },
    $errorToastFromCatch(error) {
      let message =
        error.response?.data?.message ?? error.message ?? error.toString()
      this.$errorToast({
        message,
      })
    },
    $logJson(...data) {
      try {
        data = data.map((it) => JSON.parse(JSON.stringify(it)))
        console.log(...data)
      } catch (e) {
        console.log(JSON.parse(JSON.stringify(data)))
      }
    },
  },
})

let app = createApp(
  defineComponent({
    store,
    router,
    provide: {
      store,
      router,
      axios: store.getters.axios,
      $router: router,
      $store: store,
      $logJson(...data) {
        try {
          data = data.map((it) => JSON.parse(JSON.stringify(it)))
          console.log(...data)
        } catch (e) {
          console.log(JSON.parse(JSON.stringify(data)))
        }
      },
    },
    render(h) {
      return h(CThemeProvider, [h(CReset), h(App)])
    },
    async beforeMount() {
      if (process.env.NODE_ENV === 'production') {
        window.$OneSignal = this.$OneSignal

        await this.$OneSignal.init({
          appId: "e9a022ac-5240-4464-a60c-be0dfd45f344",
          allowLocalhostAsSecureOrigin: true,
          serviceWorkerPath: "service-worker.js",
          autoRegister: true,
        })
        await this.$OneSignal.registerForPushNotifications({
          httpPermissionRequest: true,
          slidedown: true,
        })

        await this.$OneSignal.setExternalUserId("test-user-external")

        let userId = await this.$OneSignal.getUserId()
        console.log("userId", userId)

        let isPNEnabled = await this.$OneSignal.isPushNotificationsEnabled()
        console.log("isPNEnabled", isPNEnabled)

        let permission = await this.$OneSignal.getNotificationPermission()
        console.log("permission", permission)
      }
    },
  })
)

app.mount("#app")

// new Vue({
//   el: "#app",
//   render: (h) => h(CThemeProvider, [h(CReset), h(App)]),
//   router,
//   store,
// }).$mount();

// For Google Login Stuff
window.onload = function () {
  let search = new URLSearchParams(location.search)
  // if this window are opened using window.open
  if (window.opener && search.has("code")) {
    window.opener.postMessage(
      { code: search.get("code") },
      window.location.origin
    )
  }
}

