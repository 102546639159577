export const namespaced = true

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  async getChartData(ctx) {
    const axios = ctx.rootGetters.axios

    const resp = await axios
      .get(`/v1/admin/progress-tracker-master`)
      .then((r) => r.data.data)

    return resp
  },
  async getQuestions(ctx) {
    const axios = ctx.rootGetters.axios

    const resp = await axios
      .get(`/v1/admin/progress-tracker-master`)
      .then((r) => r.data.data)

    return resp
  },
  async getProgressData(ctx, clientId) {
    const axios = ctx.rootGetters.axios
    const resp = await axios
      .get(`/v1/admin/clients/${clientId}/progress-tracker`)
      .then((r) => r.data.data)

    return resp
  },
  async submission(ctx, payload) {
    const axios = ctx.rootGetters.axios
    const resp = await axios
      .post(`/v1/admin/progress-tracker`, payload)
      .then((r) => r.data)

    return resp
  },
  async getEmptyProgress(ctx) {
    const axios = ctx.rootGetters.axios
    const resp = await axios
      .get(`/v1/admin/progress-tracker-reminder`)
      .then((r) => r.data.data)

    return resp
  },
  async getProgressById(ctx, progressId) {
    const axios = ctx.rootGetters.axios
    const resp = await axios
      .get(`/v1/admin/progress-tracker/${progressId}`)
      .then((r) => r.data.data)
    return resp
  },
  async getProgressFeedbackById(ctx, progressId) {
    const axios = ctx.rootGetters.axios
    const resp = await axios
      .get(`/v1/admin/progress-tracker/${progressId}/feedback`)
      .then((r) => r.data.data)
    return resp
  },
  async getHistory(ctx) {
    const axios = ctx.rootGetters.axios
    return axios
      .get(`/v1/admin/progress-tracker/history`)
      .then((r) => r.data.data)
  },
}
