export const namespaced = true

export const state = {
  items: [],
  discount: { type: 'promo', deduction: 9900 },
  itemCheckout: {},
  clientKey: 'SB-Mid-client-3qxIWTWo9eAmtJVQ',
  transactionToken: 'c6bcfb8c-91c7-4463-954f-5cbe4be2336c'
}

export const getters = {
  isEmpty: state => state.items.length === 0,
  items: state => state.items,
  itemCheckout: state => state.itemCheckout,
  transactionToken: state => state.transactionToken,
}

export const mutations = {
  setCartItems(state, items) {
    state.items = items;
  },
  deleteCartItems(state, item) {
    state.items = state.items.filter(dt => dt.id !== item)
  },
  setItemCheckout(state, item) {
    state.itemCheckout = item
  },
  setTransactionToken(state, token) {
    state.transactionToken = token
  },
}
export const actions = {
  async list(ctx) {
    let axios = await ctx.rootGetters.axios;

    return axios
      .get(`/v1/clients/carts`)
      .then((it) => it.data)
      .then((it) => {
        ctx.commit("setCartItems", it.data);
        return it.data;
      });
  },
  onSubmitCheckout(ctx, item) {
    ctx.commit("setItemCheckout", item);
  },
  onSubmitPayment(ctx, cart) {
    let axios = ctx.rootGetters.axios;
    return axios
      .post(`/v1/clients/carts/${cart.id}/checkout`, cart.product)
      .then((it) => it.data.data)
      .then((it) => {
        console.log("@cart.create", it);
        ctx.commit('setTransactionToken', it.token)
        return it;
      });
  },
  onDeleteProduct(ctx, productId) {
    let axios = ctx.rootGetters.axios;
    return axios
      .delete(`/v1/clients/carts/${productId}`)
      .then((it) => it.data)
      .then((it) => {
        console.log("@cart.delete", it);
        ctx.commit("deleteCartItems", productId);
        return it.data;
      });
  },
}
