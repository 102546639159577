export default function getMenuCategory(menu) {
  switch (menu) {
    case "morning_snack":
      return "Snack pagi";
    case "afternoon_snack":
      return "Snack siang";
    case "lunch":
      return "Makan siang";
    case "dinner":
      return "Makan malam";
    case "night_snack":
      return "Snack malam";
    case "breakfast":
    default:
      return "Sarapan";
  }
}
