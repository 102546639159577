export default [
  {
    path: "/",
    name: "client.index",
    component: () => import("@/views/client/index.vue"),
  },
  {
    path: "/auth/verify",
    component: () => import("@/views/auth/verify.vue"),
  },
  {
    path: "/auth/resend",
    name: "auth.resend-verify",
    component: () => import("@/views/auth/resend-verify.vue"),
  },
  {
    path: "/register",
    name: "auth.register",
    component: () => import("@/views/auth/register.vue"),
  },
  {
    path: "/login",
    name: "auth.login",
    component: () => import("@/views/auth/login.vue"),
  },
  {
    path: "/login-empty",
    name: "auth.login-empty",
    component: () => import("@/views/auth/login-empty.vue"),
  },
  {
    path: "/forgot-password",
    name: "auth.forgot-password",
    component: () => import("@/views/auth/forgot-password.vue"),
  },
  {
    path: "/success",
    name: "auth.success",
    component: () => import("@/views/auth/success-page.vue"),
  },
  {
    path: "/term-condition",
    name: "client.termcondition",
    component: () => import("@/views/client/termCondition.vue"),
  },
  {
    path: "/privacy-policy",
    name: "client.privacypolicy",
    component: () => import("@/views/client/privacyPolicy.vue"),
  },
  {
    path: "/post/:id",
    name: "client.detailbanner",
    component: () => import("@/views/client/detailBanner.vue"),
  },
  {
    path: "/quizionary/:programId",
    component: () => import("@/views/client/kuisioner/container.vue"),
    children: [
      {
        path: "",
        redirect: "statement",
        name: "client.kuisioner",
      },
      {
        path: "statement",
        name: "client.statement",
        component: () => import("@/views/client/statement.vue"),
      },
      {
        path: "1",
        name: "client.kuisioner1",
        component: () => import("@/views/client/kuisioner/kuisioner-1.vue"),
      },
      {
        path: "2",
        name: "client.kuisioner2",
        component: () => import("@/views/client/kuisioner/kuisioner-2.vue"),
      },
      {
        path: "3",
        name: "client.kuisioner3",
        component: () => import("@/views/client/kuisioner/kuisioner-3.vue"),
      },
      {
        path: "4",
        name: "client.kuisioner4",
        component: () => import("@/views/client/kuisioner/kuisioner-4.vue"),
      },
      {
        path: "5",
        name: "client.kuisioner5",
        component: () => import("@/views/client/kuisioner/kuisioner-5.vue"),
      },
      {
        path: "6",
        name: "client.kuisioner6",
        component: () => import("@/views/client/kuisioner/kuisioner-6.vue"),
      },
      {
        path: "7",
        name: "client.kuisioner7",
        component: () => import("@/views/client/kuisioner/kuisioner-7.vue"),
      },
    ],
  },
  {
    path: "/nutritionists",
    name: "client.nutritionists",
    component: () => import("@/views/client/nutritionists/index.vue"),
  },
  {
    path: "/nutritionists/:nutritionistId",
    name: "client.nutritionists.detail",
    component: () => import("@/views/client/nutritionists/detail.vue"),
  },
  {
    path: "/management-client",
    name: "nutrisionist.managementClient",
    component: () =>
      import("@/views/nutritionists/client/management-client/index.vue"),
  },
  {
    path: "/history-questionnaire",
    name: "nutrisionist.historyQuestionnaire",
    component: () =>
      import("@/views/nutritionists/client/historyQuestionnaire.vue"),
  },
  {
    path: "/cart",
    name: "client.cart",
    component: () => import("@/views/client/cart"),
  },
  {
    path: "/cart/checkout",
    name: "client.checkout",
    component: () => import("@/views/client/checkout"),
  },
  {
    path: "/cart/checkout/payment",
    name: "client.payment",
    component: () => import("@/views/client/checkout/payment"),
  },
  {
    path: "/paid/success",
    name: "client.success",
    component: () => import("@/views/client/checkout/success.vue"),
  },
  {
    path: "/profile",
    component: () => import("@/views/profile/layout.vue"),
    children: [
      {
        path: "",
        name: "client.profile",
        component: () => import("@/views/profile/index.vue"),
        beforeEnter: (to, from, next) => {
          let match = window.matchMedia("(min-width: 30em)")

          if (match.matches) {
            return next({ name: "client.profile.detail" })
          }
          return next()
        },
      },
      {
        path: "detail",
        name: "client.profile.detail",
        component: () => import("@/views/profile/detail/index.vue"),
      },
      {
        path: "data-diet",
        name: "client.profile.data-diet",
        component: () => import("@/views/profile/data-diet.vue"),
      },
      {
        path: "program",
        name: "client.profile.program",
        component: () => import("@/views/profile/program.vue"),
      },
      {
        path: "pembelian",
        name: "client.profile.pembelian",
        component: () => import("@/views/profile/pembelian.vue"),
      },
      {
        path: "settings",
        name: "client.profile.settings",
        component: () => import("@/views/profile/settings.vue"),
      },
      {
        path: "questionary/:programId",
        name: "client.profile.detail-quizionary",
        component: () => import("@/views/profile/detail/quizionary.vue"),
      },
    ],
  },
  {
    path: "/nutritionist",
    component: () => import("@/views/nutritionists/layout.vue"),
    meta: { isNutritionist: true },
    children: [
      {
        path: ":clientId",
        component: {
          name: "NutritionistPage",
          render: (h) => h("router-view"),
        },
        children: [
          {
            path: "",
            name: "nutri.detail-client",
            component: () => import("@/views/nutritionists/client/detail.vue"),
          },
          {
            path: "meal-plan",
            meta: { mealPlanNav: true },
            component: {
              name: "MealPlanContainer",
              render(h) {
                return h("router-view")
              },
            },
            children: [
              {
                path: "",
                name: "nutri.meal-plan",
                component: () => import("@/views/meal-plan/index.vue"),
              },
              {
                path: "edit",
                name: "nutri.meal-plan.edit",
                component: () => import("@/views/meal-plan/edit.vue"),
              },
            ],
          },
          {
            path: "recommended-menu",
            meta: { mealPlanNav: true },
            component: {
              name: "RecommendedMenuContainer",
              render(h) {
                return h("router-view")
              },
            },
            children: [
              {
                path: "",
                name: "nutri.rekomendasi-menu",
                component: () =>
                  import("@/views/meal-plan/menu-recommendation/index.vue"),
              },
              {
                path: "edit",
                name: "nutri.rekomendasi-menu.edit",
                component: () =>
                  import("@/views/meal-plan/menu-recommendation/edit.vue"),
              },
            ],
          },
          {
            path: "health-profile",
            meta: { mealPlanNav: true },
            component: {
              name: "HealthProfileContainer",
              render(h) {
                return h("router-view")
              },
            },
            children: [
              {
                path: "",
                name: "nutri.profile-gizi",
                component: () =>
                  import("@/views/meal-plan/ahli-gizi/index.vue"),
              },
              {
                path: "edit-profile-gizi",
                name: "nutri.profile-client-edit",
                component: () =>
                  import("@/views/meal-plan/edit-profile-client.vue"),
              },
              {
                path: "edit-nutrition",
                name: "nutri.nutrition-edit",
                component: () => import("@/views/meal-plan/edit-nutrition.vue"),
              },
              {
                path: "edit-body-composition",
                name: "nutri.body-composition-edit",
                component: () =>
                  import("@/views/meal-plan/edit-body-composition.vue"),
              },
              {
                path: "edit-nutrition-advice",
                name: "nutri.nutrition-advice-edit",
                component: () =>
                  import("@/views/meal-plan/edit-nutrition-advice.vue"),
              },
            ],
          },
          {
            path: "quizionary/:programId",
            name: "nutri.detail-quizionary",
            component: () =>
              import("@/views/nutritionists/quizionary/detail.vue"),
          },
          {
            path: "adime-note",
            component: {
              name: "AdimeNoteLayout",
              render: (h) => h("router-view"),
            },
            children: [
              {
                path: "",
                name: "nutri.adime-note",
                component: () =>
                  import("@/views/nutritionists/adime-note/index.vue"),
              },
              {
                path: "history",
                name: "nutri.adime-note.history",
                component: () =>
                  import("@/views/nutritionists/adime-note/history/index.vue"),
              },
              {
                path: "f",
                name: "nutri.adime-note.form",
                component: () =>
                  import("@/views/nutritionists/adime-note/form/index.vue"),
              },
            ],
          },
          {
            path: "follow-up-notes",
            component: {
              name: "FollowUpNotePage",
              render: (h) => h("router-view"),
            },
            children: [
              {
                path: "",
                name: "nutri.follow-up-notes",
                component: () =>
                  import("@/views/nutritionists/follow-up-notes/index.vue"),
              },
              {
                path: "history",
                name: "nutri.follow-up-notes.history",
                component: () =>
                  import(
                    "@/views/nutritionists/follow-up-notes/history/index.vue"
                  ),
              },
              {
                path: "f",
                name: "nutri.follow-up-notes.form",
                component: () =>
                  import(
                    "@/views/nutritionists/follow-up-notes/form/index.vue"
                  ),
              },
            ],
          },
          {
            path: "progress-tracker",
            component: () =>
              import("@/views/nutritionists/progress-tracker/index.vue"),
            children: [
              {
                path: "",
                name: "nutri.progress-tracker",
                redirect: { name: "nutri.progress-tracker.master" },
              },
              {
                path: "i",
                name: "nutri.progress-tracker.indikator",
                component: () =>
                  import(
                    "@/views/nutritionists/progress-tracker/indikator/index.vue"
                  ),
              },
              {
                path: "m",
                component: {
                  name: "NutritionistProgressTrackerMasterContainer",
                  render: (h) => h("router-view"),
                },
                children: [
                  {
                    path: "",
                    name: "nutri.progress-tracker.master",
                    component: () =>
                      import(
                        "@/views/nutritionists/progress-tracker/master/index.vue"
                      ),
                  },
                ],
              },
            ],
          },
          {
            path: "progress-tracker/edit",
            name: "nutri.progress-tracker.edit",
            component: () =>
              import(
                "@/views/nutritionists/progress-tracker/master/edit/index.vue"
              ),
          },
        ],
      },
    ],
  },
  {
    path: "/progress-tracker/feedback/:clientId/:progressId",
    name: "nutri.progress-tracker.detail",
    component: () =>
      import("@/views/nutritionists/progress-tracker/detail/index.vue"),
  },
  {
    path: "/meal-plan",
    component: () => import("@/views/meal-plan/layout.vue"),
    children: [
      {
        path: "",
        name: "meal-plan",
        component: () => import("@/views/meal-plan/index.vue"),
      },
      {
        path: "/edit-meal-plan",
        name: "meal-plan-edit",
        component: () => import("@/views/meal-plan/edit.vue"),
      },
      {
        path: "/edit-profile-client",
        name: "profile-client-edit",
        component: () => import("@/views/meal-plan/edit-profile-client.vue"),
      },
      {
        path: "/edit-nutrition",
        name: "nutrition-edit",
        component: () => import("@/views/meal-plan/edit-nutrition.vue"),
      },
      {
        path: "/edit-body-composition",
        name: "body-composition-edit",
        component: () => import("@/views/meal-plan/edit-body-composition.vue"),
      },
      {
        path: "/edit-nutrition-advice",
        name: "nutrition-advice-edit",
        component: () => import("@/views/meal-plan/edit-nutrition-advice.vue"),
      },
      {
        path: "/edit-menu-recomendation",
        name: "menu-recomendation-edit",
        component: () =>
          import("@/views/meal-plan/menu-recommendation/edit.vue"),
      },
      {
        path: "recommended-menu",
        name: "meal-plan.rekomendasi-menu",
        component: () =>
          import("@/views/meal-plan/menu-recommendation/index.vue"),
      },
      {
        path: "health-profile",
        name: "meal-plan.profile-gizi",
        component: () => import("@/views/meal-plan/ahli-gizi/index.vue"),
      },
      {
        path: ":clientId",
        name: "meal-plan.with-id",
        component: () => import("@/views/meal-plan/index.vue"),
      },
    ],
  },
  {
    path: "/content-challenge",
    component: () => import("@/views/content-challenge/layout.vue"),
    children: [
      {
        path: "daily-coaching",
        name: "content-challenge.daily-coaching",
        component: () => import("@/views/content-challenge/daily-coaching.vue"),
      },
      {
        path: "",
        name: "content-challenge.coaching-course",
        component: () =>
          import("@/views/content-challenge/coaching-course.vue"),
      },
      {
        path: "diet-challenge",
        name: "content-challenge.diet-challenge",
        component: () => import("@/views/content-challenge/diet-challenge.vue"),
      },
    ],
  },
  {
    path: "/content-challenge/detail/:id",
    name: "content-challenge.detail",
    component: () => import("@/views/content-challenge/detail/index.vue"),
  },
  {
    path: "/glossaries",
    name: "glossaries",
    component: () => import("@/views/glossaries/index.vue"),
  },
  {
    path: "/progress-tracker",
    component: () => import("@/views/client/progress-tracker/index.vue"),
    children: [
      {
        path: "",
        name: "client.progress-tracker",
        redirect: "current",
      },
      {
        path: "current",
        name: "client.progress-tracker.current",
        component: () => import("@/views/client/progress-tracker/current.vue"),
      },
      {
        path: "history",
        name: "client.progress-tracker.history",
        component: () => import("@/views/client/progress-tracker/history.vue"),
      },
    ],
  },
  {
    path: "/progress-tracker/detail/:progressId",
    name: "client.progress-tracker.detail",
    component: () => import("@/views/client/progress-tracker/detail/index.vue"),
  },
  {
    path: "/progress-tracker/submission/:date",
    name: "client.progress-tracker.submission",
    component: () => import("@/views/client/progress-tracker/submission.vue"),
  },
  {
    path: "/progress-tracker/success",
    name: "client.progress-tracker.success",
    component: () => import("@/views/client/progress-tracker/success.vue"),
  },
  {
    path: "/notes",
    component: () => import("@/views/client/follow-up-notes/layout.vue"),
    children: [
      {
        path: "",
        name: "client.follow-up-notes",
        component: () => import("@/views/client/follow-up-notes/index.vue"),
      },
    ],
  },

  // start admin
  {
    path: "/admin",
    component: () => import("@/views/admin/layout.vue"),
    children: [
      {
        path: "",
        name: "admin",
        component: () => import("@/views/admin/dashboard/index.vue"),
      },
      {
        path: "/admin/diet-program",
        component: {
          name: "AdminDietProgramContainer",
          render: (h) => h("router-view"),
        },
        children: [
          {
            path: "",
            name: "admin.diet-program",
            component: () => import("@/views/admin/diet-program/index.vue"),
          },
          {
            path: "add",
            name: "admin.diet-program.add",
            component: () => import("@/views/admin/diet-program/form.vue"),
          },
          {
            path: "edit/:id",
            name: "admin.diet-program.edit",
            component: () => import("@/views/admin/diet-program/form.vue"),
          },
          {
            path: ":id",
            name: "admin.diet-program.detail",
            component: () => import("@/views/admin/diet-program/detail.vue"),
          },
        ],
      },
      {
        path: "/admin/clients",
        component: {
          name: "AdminClientContainer",
          render: (h) => h("router-view"),
        },
        children: [
          {
            path: "",
            name: "admin.clients",
            component: () => import("@/views/admin/clients/index.vue"),
          },
          {
            path: "new",
            name: "admin.clients-new",
            component: () => import("@/views/admin/clients/create.vue"),
          },
          {
            path: ":clientId",
            component: {
              name: "SUClientSingleContainer",
              render: (h) => h("router-view"),
            },
            children: [
              {
                path: "",
                name: "admin.clients-detail",
                component: () => import("@/views/admin/clients/detail.vue"),
              },
              {
                path: "p",
                component: {
                  name: "AdminClientProgramContainer",
                  render: (h) => h("router-view"),
                },
                children: [
                  {
                    path: ":programId",
                    component: {
                      name: "AdminClientProgramsDetailContainer",
                      render: (h) => h("router-view"),
                    },
                    children: [
                      {
                        path: "",
                        name: "admin.clients-programs",
                        component: () =>
                          import("@/views/admin/clients/programs/index.vue"),
                      },
                      {
                        path: "nutris",
                        name: "admin.clients-nutris",
                        component: () =>
                          import("@/views/admin/clients/nutris/index.vue"),
                      },
                      {
                        path: "mp",
                        component: {
                          name: "AdminClientMealPlanContainer",
                          render: (h) => h("router-view"),
                        },
                        children: [
                          {
                            path: "",
                            name: "admin.clients-mealplan",
                            redirect: "plan",
                          },
                          {
                            path: ":moduleId",
                            name: "admin.clients-mealplan.module",
                            component: () =>
                              import(
                                "@/views/admin/clients/mealplan/index.vue"
                              ),
                          },
                        ],
                      },
                      {
                        path: "quizionary",
                        name: "admin.clients-quizionary",
                        component: () =>
                          import("@/views/admin/clients/quizionary/index.vue"),
                      },
                      {
                        path: "note",
                        name: "admin.clients-followup-notes",
                        component: () =>
                          import(
                            "@/views/admin/clients/follow-up-notes/index.vue"
                          ),
                      },
                      {
                        path: "progress",
                        name: "admin.clients-progress",
                        component: () =>
                          import(
                            "@/views/admin/clients/progress/index.vue"
                          ),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "/admin/coaching-challenge",
        component: {
          name: "AdminCoachingChallengeContainer",
          render: (h) => h("router-view"),
        },
        children: [
          {
            path: "",
            name: "admin.coaching-challenge",
            component: () =>
              import("@/views/admin/coaching-challenge/index.vue"),
          },
          {
            path: "add",
            name: "admin.coaching-challenge.add",
            component: () =>
              import("@/views/admin/coaching-challenge/form.vue"),
          },
          {
            path: "edit/:id",
            name: "admin.coaching-challenge.edit",
            component: () =>
              import("@/views/admin/coaching-challenge/form.vue"),
          },
          {
            path: ":id",
            name: "admin.coaching-challenge.detail",
            component: () =>
              import("@/views/admin/coaching-challenge/detail.vue"),
          },
        ],
      },
      {
        path: "/admin/nutritionists",
        component: {
          name: "AdminNutritionistsContainer",
          render: (h) => h("router-view"),
        },
        children: [
          {
            path: "",
            name: "admin.nutritionists",
            component: () => import("@/views/admin/nutritionists/index.vue"),
          },
          {
            path: "add",
            name: "admin.nutritionists.add",
            component: () => import("@/views/admin/nutritionists/form.vue"),
          },
          {
            path: "edit/:id",
            name: "admin.nutritionists.edit",
            component: () => import("@/views/admin/nutritionists/form.vue"),
          },
          {
            path: ":id",
            name: "admin.nutritionists.detail",
            component: () => import("@/views/admin/nutritionists/detail.vue"),
          },
        ],
      },
      {
        path: "/admin/notifications",
        name: "admin.notifications",
        component: () => import("@/views/admin/notifications/index.vue"),
      },
      {
        path: "/admin/diet-kuesioner",
        component: {
          name: "AdminDietKuesionerContainer",
          render: (h) => h("router-view"),
        },
        children: [
          {
            path: "",
            name: "admin.diet-kuesioner",
            component: () => import("@/views/admin/diet-kuesioner/index.vue"),
          },
          {
            path: "add",
            name: "admin.diet-kuesioner.add",
            component: () => import("@/views/admin/diet-kuesioner/form.vue"),
          },
          {
            path: "edit/:id",
            name: "admin.diet-kuesioner.edit",
            component: () => import("@/views/admin/diet-kuesioner/form.vue"),
          },
          {
            path: ":id",
            name: "admin.diet-kuesioner.detail",
            component: () => import("@/views/admin/diet-kuesioner/detail.vue"),
          },
        ],
      },
      {
        path: "glossary",
        component: {
          name: "SUGlossaryContainer",
          render: (h) => h("router-view"),
        },
        children: [
          {
            path: "",
            name: "admin.glossary",
            component: () => import("@/views/admin/glossary/index.vue"),
          },
          {
            path: "new",
            name: "admin.glossary-new",
            component: () => import("@/views/admin/glossary/create.vue"),
          },
          {
            path: ":glossaryId",
            component: {
              name: "SUGlossaryDetailContainer",
              render: (h) => h("router-view"),
            },
            children: [
              {
                path: "",
                name: "admin.glossary-detail",
                component: () => import("@/views/admin/glossary/detail.vue"),
              },
              {
                path: "edit",
                name: "admin.glossary-edit",
                component: () => import("@/views/admin/glossary/edit.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "/admin/log-activity",
        name: "admin.log-activity",
        component: () => import("@/views/admin/log-activity/index.vue"),
      },
      {
        path: "/admin/coupons",
        component: {
          name: "AdminCouponsContainer",
          render: (h) => h("router-view"),
        },
        children: [
          {
            path: "",
            name: "admin.coupons",
            component: () => import("@/views/admin/coupons/index.vue"),
          },
          {
            path: "add",
            name: "admin.coupons.add",
            component: () => import("@/views/admin/coupons/form.vue"),
          },
          {
            path: "edit/:id",
            name: "admin.coupons.edit",
            component: () => import("@/views/admin/coupons/form.vue"),
          },
          {
            path: ":id",
            name: "admin.coupons.detail",
            component: () => import("@/views/admin/coupons/detail.vue"),
          },
        ],
      },
      {
        path: "/admin/notifications",
        component: {
          name: "AdminNotificationsContainer",
          render: (h) => h("router-view"),
        },
        children: [
          {
            path: "",
            name: "admin.notifikasi",
            component: () => import("@/views/admin/notifications/index.vue"),
          },
          {
            path: "new",
            name: "admin.notifikasi-new",
            component: () => import("@/views/admin/notifications/create.vue"),
          },
          {
            path: ":notifikasiId",
            component: {
              name: "AdminNotifikasiSingleContainer",
              render: (h) => h("router-view"),
            },
            children: [
              {
                path: "",
                name: "admin.notifikasi-detail",
                component: () =>
                  import("@/views/admin/notifications/detail.vue"),
              },
              {
                path: "edit",
                name: "admin.notifikasi-edit",
                component: () => import("@/views/admin/notifications/edit.vue"),
              },
            ],
          },
        ],
      },
    ],
  },

  {
    path: "/management",
    component: () => import("@/views/superadmin/layout.vue"),
    children: [
      {
        path: "",
        name: "superadmin",
        component: () => import("@/views/superadmin/dashboard/index.vue"),
      },
      {
        path: "/management/diet-program",
        component: {
          name: "SUDietProgramContainer",
          render: (h) => h("router-view"),
        },
        children: [
          {
            path: "",
            name: "superadmin.diet-program",
            component: () =>
              import("@/views/superadmin/diet-program/index.vue"),
          },
          {
            path: "add",
            name: "superadmin.diet-program.add",
            component: () => import("@/views/superadmin/diet-program/form.vue"),
          },
          {
            path: "edit/:id",
            name: "superadmin.diet-program.edit",
            component: () => import("@/views/superadmin/diet-program/form.vue"),
          },
          {
            path: ":id",
            name: "superadmin.diet-program.detail",
            component: () =>
              import("@/views/superadmin/diet-program/detail.vue"),
          },
        ],
      },
      {
        path: "/management/admin",
        component: {
          name: "SUAdminContainer",
          render: (h) => h("router-view"),
        },
        children: [
          {
            path: "",
            name: "superadmin.admin",
            component: () => import("@/views/superadmin/admin/index.vue"),
          },
          {
            path: "add",
            name: "superadmin.admin.add",
            component: () => import("@/views/superadmin/admin/form.vue"),
          },
          {
            path: "edit/:id",
            name: "superadmin.admin.edit",
            component: () => import("@/views/superadmin/admin/form.vue"),
          },
        ],
      },
      {
        path: "/management/clients",
        component: {
          name: "SUClientContainer",
          render: (h) => h("router-view"),
        },
        children: [
          {
            path: "",
            name: "superadmin.clients",
            component: () => import("@/views/superadmin/clients/index.vue"),
          },
          {
            path: "new",
            name: "superadmin.clients-new",
            component: () => import("@/views/superadmin/clients/create.vue"),
          },
          {
            path: ":clientId",
            component: {
              name: "SUClientSingleContainer",
              render: (h) => h("router-view"),
            },
            children: [
              {
                path: "",
                name: "superadmin.clients-detail",
                component: () =>
                  import("@/views/superadmin/clients/detail.vue"),
              },
              {
                path: "edit",
                name: "superadmin.clients-edit",
                component: () => import("@/views/superadmin/clients/edit.vue"),
              },
              {
                path: "p",
                component: {
                  name: "SUClientProgramsContainer",
                  render: (h) => h("router-view"),
                },
                children: [
                  {
                    path: ":programId",
                    component: {
                      name: "SUClientProgramsDetailContainer",
                      render: (h) => h("router-view"),
                    },
                    children: [
                      {
                        path: "",
                        name: "superadmin.clients-programs",
                        component: () =>
                          import(
                            "@/views/superadmin/clients/programs/index.vue"
                          ),
                      },
                      {
                        path: "nutris",
                        name: "superadmin.clients-nutris",
                        component: () =>
                          import("@/views/superadmin/clients/nutris/index.vue"),
                      },
                      {
                        path: "mp",
                        component: {
                          name: "SUClientMealPlanContainer",
                          render: (h) => h("router-view"),
                        },
                        children: [
                          {
                            path: "",
                            name: "superadmin.clients-mealplan",
                            redirect: "plan",
                          },
                          {
                            path: ":moduleId",
                            name: "superadmin.clients-mealplan.module",
                            component: () =>
                              import(
                                "@/views/superadmin/clients/mealplan/index.vue"
                              ),
                          },
                        ],
                      },
                      {
                        path: "quizionary",
                        name: "superadmin.clients-quizionary",
                        component: () =>
                          import(
                            "@/views/superadmin/clients/quizionary/index.vue"
                          ),
                      },
                      {
                        path: "note",
                        name: "superadmin.clients-followup-notes",
                        component: () =>
                          import(
                            "@/views/superadmin/clients/follow-up-notes/index.vue"
                          ),
                      },
                      {
                        path: "progress",
                        name: "superadmin.clients-progress",
                        component: () =>
                          import(
                            "@/views/superadmin/clients/progress/index.vue"
                          ),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "/management/coaching-challenge",
        component: {
          name: "SUCoachingChallengeContainer",
          render: (h) => h("router-view"),
        },
        children: [
          {
            path: "",
            name: "superadmin.coaching-challenge",
            component: () =>
              import("@/views/superadmin/coaching-challenge/index.vue"),
          },
          {
            path: "add",
            name: "superadmin.coaching-challenge.add",
            component: () =>
              import("@/views/superadmin/coaching-challenge/form.vue"),
          },
          {
            path: "edit/:id",
            name: "superadmin.coaching-challenge.edit",
            component: () =>
              import("@/views/superadmin/coaching-challenge/form.vue"),
          },
          {
            path: ":id",
            name: "superadmin.coaching-challenge.detail",
            component: () =>
              import("@/views/superadmin/coaching-challenge/detail.vue"),
          },
        ],
      },
      {
        path: "/management/nutritionists",
        component: {
          name: "SUNutritionistsContainer",
          render: (h) => h("router-view"),
        },
        children: [
          {
            path: "",
            name: "superadmin.nutritionists",
            component: () =>
              import("@/views/superadmin/nutritionists/index.vue"),
          },
          {
            path: "add",
            name: "superadmin.nutritionists.add",
            component: () =>
              import("@/views/superadmin/nutritionists/form.vue"),
          },
          {
            path: "edit/:id",
            name: "superadmin.nutritionists.edit",
            component: () =>
              import("@/views/superadmin/nutritionists/form.vue"),
          },
          {
            path: ":id",
            name: "superadmin.nutritionists.detail",
            component: () =>
              import("@/views/superadmin/nutritionists/detail.vue"),
          },
        ],
      },
      {
        path: "/management/notifications",
        component: {
          name: "SUNotificationsContainer",
          render: (h) => h("router-view"),
        },
        children: [
          {
            path: "",
            name: "superadmin.notifikasi",
            component: () =>
              import("@/views/superadmin/notifications/index.vue"),
          },
          {
            path: "new",
            name: "superadmin.notifikasi-new",
            component: () =>
              import("@/views/superadmin/notifications/create.vue"),
          },
          {
            path: ":notifikasiId",
            component: {
              name: "SUNotifikasiSingleContainer",
              render: (h) => h("router-view"),
            },
            children: [
              {
                path: "",
                name: "superadmin.notifikasi-detail",
                component: () =>
                  import("@/views/superadmin/notifications/detail.vue"),
              },
              {
                path: "edit",
                name: "superadmin.notifikasi-edit",
                component: () =>
                  import("@/views/superadmin/notifications/edit.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "/management/diet-kuesioner",
        component: {
          name: "SUDietKuesionerContainer",
          render: (h) => h("router-view"),
        },
        children: [
          {
            path: "",
            name: "superadmin.diet-kuesioner",
            component: () =>
              import("@/views/superadmin/diet-kuesioner/index.vue"),
          },
          {
            path: "add",
            name: "superadmin.diet-kuesioner.add",
            component: () =>
              import("@/views/superadmin/diet-kuesioner/form.vue"),
          },
          {
            path: "edit/:id",
            name: "superadmin.diet-kuesioner.edit",
            component: () =>
              import("@/views/superadmin/diet-kuesioner/form.vue"),
          },
          {
            path: ":id",
            name: "superadmin.diet-kuesioner.detail",
            component: () =>
              import("@/views/superadmin/diet-kuesioner/detail.vue"),
          },
        ],
      },
      {
        path: "/management/glossary",
        component: {
          name: "SUGlossaryContainer",
          render: (h) => h("router-view"),
        },
        children: [
          {
            path: "",
            name: "superadmin.glossary",
            component: () => import("@/views/superadmin/glossary/index.vue"),
          },
          {
            path: "new",
            name: "superadmin.glossary-new",
            component: () => import("@/views/superadmin/glossary/create.vue"),
          },
          {
            path: ":glossaryId",
            component: {
              name: "SUGlossaryDetailContainer",
              render: (h) => h("router-view"),
            },
            children: [
              {
                path: "",
                name: "superadmin.glossary-detail",
                component: () =>
                  import("@/views/superadmin/glossary/detail.vue"),
              },
              {
                path: "edit",
                name: "superadmin.glossary-edit",
                component: () => import("@/views/superadmin/glossary/edit.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "/management/log-activity",
        name: "superadmin.log-activity",
        component: () => import("@/views/superadmin/log-activity/index.vue"),
      },
      {
        path: "/management/coupons",
        component: {
          name: "SUCouponsContainer",
          render: (h) => h("router-view"),
        },
        children: [
          {
            path: "",
            name: "superadmin.coupons",
            component: () => import("@/views/superadmin/coupons/index.vue"),
          },
          {
            path: "add",
            name: "superadmin.coupons.add",
            component: () => import("@/views/superadmin/coupons/form.vue"),
          },
          {
            path: "edit/:id",
            name: "superadmin.coupons.edit",
            component: () => import("@/views/superadmin/coupons/form.vue"),
          },
          {
            path: ":id",
            name: "superadmin.coupons.detail",
            component: () => import("@/views/superadmin/coupons/detail.vue"),
          },
        ],
      },
    ],
  },
]
