export const namespaced = true

export const state = {
  universities: [],
}

export const getters = {
  universities: (state) => state.universities,
}

export const mutations = {
  setUniversities(state, universities) {
    state.universities = universities;
  },
}

export const actions = {
  loadUniversities(ctx) {
    let axios = ctx.rootGetters.axios;
    return axios
      .get(`/v1/general/universities`)
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit("setUniversities", it);
      })
      .catch((err) => {
        throw err.response;
      });
  },
  createUniversities(ctx, data) {
    let axios = ctx.rootGetters.axios;
    return axios
      .post(`/v1/general/universities`, data)
      .then((it) => it.data.data)
      .then((it) => {
        return it;
      })
      .catch((err) => {
        throw err.response;
      });
  },
}