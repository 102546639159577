export const namespaced = true;
export const state = {
  items: [],
  item: {},
  meta: {},
};
export const mutations = {
  setItems(state, items) {
    state.items = items;
  },
  setItem(state, item) {
    state.item = item;
  },
  setMeta(state, meta) {
    state.meta = meta;
  },
};
export const actions = {
  async listProgramSuperadmin(ctx, params) {
    let axios = ctx.rootGetters.axios;
    return axios
      .get(`/v1/super-admin/products`, { params: params })
      .then((it) => it.data)
      .then((it) => {
        ctx.commit("setItems", it.data);
        ctx.commit("setMeta", it.meta);

        return it.data;
      })
      .catch(() => {
        ctx.commit("setItems", []);
        ctx.commit("setMeta", 1);
      });
  },
  createProgramSuperadmin(ctx, data) {
    let axios = ctx.rootGetters.axios;
    return axios
      .post(`/v1/super-admin/products`, data)
      .then((it) => it.data.data)
      .then((it) => {
        return it;
      })
      .catch((err) => {
        throw err.response;
      });
  },
  detailProgramSuperadmin(ctx, id) {
    let axios = ctx.rootGetters.axios;
    return axios
      .get(`/v1/super-admin/products/${id}`)
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit("setItem", it);
        return it;
      })
      .catch((err) => {
        throw err.response;
      });
  },

  deleteProgramSuperadmin(ctx, id) {
    let axios = ctx.rootGetters.axios;
    return axios
      .delete(`/v1/super-admin/products/${id}`)
      .then((it) => it.data.data)
      .then((it) => {
        console.log("removed", it);
      })
      .catch((err) => {
        throw err.response;
      });
  },
  updateProgramSuperadmin(ctx, data) {
    let axios = ctx.rootGetters.axios;
    return axios
      .put(`/v1/super-admin/products/${data.id}`, data)
      .then((it) => it.data.data)
      .then((it) => {
        console.log("updated", it);
      })
      .catch((err) => {
        throw err.response;
      });
  },
};
