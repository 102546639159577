export const namespaced = true
export const state = () => ({
  purchaseHistory: [],
});

export const getters = {
  purchaseHistory: state => state.purchaseHistory,
};

export const mutations = {
  setPurchaseHistory(state, products) {
    state.purchaseHistory = products
  },
};

export const actions = {
  async listPurchaseHistory(ctx) {
    let axios = ctx.rootGetters.axios;
    return axios
      .get(`/v1/clients/orders`)
      .then((it) => it.data)
      .then((it) => it.data)
      .then((it) => {
        ctx.commit("setPurchaseHistory", it);
      });
  },
};
