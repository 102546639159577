import Vue from "vue"

export const namespaced = true

export const state = {
  clients: [],
  filters: {},
  meta: { totalData: 0 },
  followUpNotes: {},
}

export const getters = {
  clientById: (state) => (clientId) =>
    state.clients.find((it) => it.id === clientId),
}

export const mutations = {
  setClients(state, payload) {
    state.clients = payload
  },
  setClient(state, client) {
    let index = state.clients.findIndex((it) => it.id === client.id)
    if (index === -1) {
      state.clients.push(client)
    } else {
      Vue.set(state.clients, index, client)
    }
  },
  setFilter(state, { key, value }) {
    Vue.set(state, key, value)
  },
  setTotalData(state, total) {
    state.meta.totalData = total
  },
  setFollowUpNOtes(state, notes) {
    state.followUpNotes = notes
  },
}

let getApiPrefix = (role) => (role === "super_admin" ? "super-admin" : "admin")

export const actions = {
  listClients(ctx, { page, perPage, search, status } = {}) {
    let role = ctx.rootState.auth.user.role
    let prefix = getApiPrefix(role)

    // eslint-disable-next-line no-unreachable
    let axios = ctx.rootGetters.axios
    let params = new URLSearchParams()
    if (page != null) params.set("page", page)
    if (perPage != null) params.set("perpage", perPage)
    if (search != null) params.set("q", search)
    if (status != null) params.set("status", status)

    return axios
      .get(`/v1/${prefix}/clients?${params.toString()}`)
      .then((r) => r.data)
      .then((data) => {
        ctx.commit("setClients", data.data)
        ctx.commit("setTotalData", data.meta.total)
        return data.data
      })
  },
  getClientById(ctx, clientId) {
    let axios = ctx.rootGetters.axios
    let role = ctx.rootState.auth.user.role
    let prefix = getApiPrefix(role)

    return axios
      .get(`/v1/${prefix}/clients/${clientId}`)
      .then((r) => r.data.data)
      .then((client) => {
        ctx.commit("setClient", client)
        return client
      })
  },
  createClient(ctx, client) {
    let axios = ctx.rootGetters.axios
    return axios
      .post(`/v1/super-admin/clients`, client)
      .then((r) => r.data.data)
      .then((r) => {
        ctx.commit("setClient", r)
        return r
      })
  },
  updateClient(ctx, client) {
    let axios = ctx.rootGetters.axios
    return axios
      .put(`/v1/super-admin/clients/${client.id}`, client)
      .then((r) => r.data.data)
      .then((r) => {
        ctx.commit("setClient", r)
        return r
      })
  },
  getProgramById(ctx, programId) {
    let axios = ctx.rootGetters.axios
    let role = ctx.rootState.auth.user.role
    let prefix = getApiPrefix(role)

    return axios
      .get(`/v1/${prefix}/programs/${programId}/details`)
      .then((r) => r.data.data)
      .then((program) => {
        // ctx.commit("setProgram", program);
        return program
      })
  },
  listNutritionist(ctx, { query, page } = {}) {
    let axios = ctx.rootGetters.axios
    let role = ctx.rootState.auth.user.role
    let prefix = getApiPrefix(role)

    let params = new URLSearchParams()
    params.set("page", page)
    if (query != null) params.set("q", query)

    return axios
      .get(`/v1/${prefix}/nutritionist?${params.toString()}`)
      .then((r) => r.data)
  },
  updateProgramNutritionist(ctx, { programId, nutritionistId }) {
    let axios = ctx.rootGetters.axios
    return axios
      .post(`/v1/super-admin/programs/${programId}/update-nutritionist`, {
        nutritionistId,
      })
      .then((r) => r.data.data)
  },
  getFollowUpNotes(ctx, clientId) {
    let axios = ctx.rootGetters.axios
    let role = ctx.rootState.auth.user.role
    let prefix = getApiPrefix(role)

    return axios
      .get(`/v1/${prefix}/clients/${clientId}/follow-up-notes`)
      .then((r) => r.data.data)
      .then((data) => {
        ctx.commit("setFollowUpNOtes", data)
        return data
      })
  },
}
