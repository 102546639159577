export let namespaced = true;
export let state = {
  data: [],
  meals: [],
};
export let getters = {
  glossaries(state) {
    let result = [];
    for (let item of state.data) {
      let index = result.findIndex((it) => it.name === item.foodGroup);
      if (index < 0) {
        result.push({
          name: item.foodGroup,
          items: [item],
        });
      } else {
        result[index].items.push(item);
      }
    }

    return result;
  },
};
export let mutations = {
  setGlossaries(state, glossaries) {
    state.data = glossaries;
  },
  setMeals(state, meals) {
    state.meals = meals;
  },
};
export let actions = {
  list(ctx, { q, foodGroup, char } = {}) {
    let axios = ctx.rootGetters.axios;
    let params = new URLSearchParams();
    params.set("perpage", 100);
    if (q != null && q !== "") params.set("q", q);
    if (foodGroup != null && foodGroup !== "") params.set("foodGroup", foodGroup);

    if (char == null && (q == null || q === "")) char = "a";
    if (char != null && char !== "") params.set("char", char);

    return axios
      .get(`/v1/meal-glossaries?${params.toString()}`)
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit("setGlossaries", it.result);
        return it;
      });
  },
  listMeals(ctx) {
    let axios = ctx.rootGetters.axios;
    return axios
      .get(`/v1/meal-glossaries/food-ingredients`)
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit("setMeals", it);
      });
  },
};
