import Vue from "vue";

export const namespaced = true;

export const state = () => ({
  hi: true,
  data: {},
  totalPage: 0,
  perPage: 10,
  currentPage: 1,
  rencanaMakan: [],
  rekomendasiMenu: [],
  profileGizi: null,
  asupanGizi: null,
  kondisiTubuh: null,
  saran: null,

  products: [],
  product: {},
  isOpen: false,
  nutritionist: {},
  kuisioner: {},

  successPayload: {},

  notifications: [],
});

export const getters = {
  listClient: (s) => Object.values(s.data),
  clientWithId: (s) => (id) => s.data[id],
  products: state => state.products,
  product: state => state.product,
  isOpen: state => state.isOpen,
  nutritionist: state => state.nutritionist,
  successPayload: state => state.successPayload,
  notifications: state => state.notifications,
};

export const mutations = {
  setTotalPage(state, total) {
    state.totalPage = total;
  },
  setCurrentPage(state, current) {
    state.currentPage = current;
  },
  setClients(state, clients) {
    for (let client of clients) {
      client.lastName = client.LastName;
      // state.data[client.id] = client
      Vue.set(state.data, client.id, client);
    }
  },
  setRencanaMakan(state, rencana) {
    state.rencanaMakan = rencana;
  },
  setRekomendasiMenu(state, rekomendasi) {
    state.rekomendasiMenu = rekomendasi;
  },
  setProfileGizi(state, profile) {
    state.profileGizi = profile;
  },
  setAsupanGizi(state, asupan) {
    state.asupanGizi = asupan;
  },
  setKondisiTubuh(state, kondisi) {
    state.kondisiTubuh = kondisi;
  },
  setSaran(state, saran) {
    state.saran = saran;
  },
  setProducts(state, products) {
    state.products = products;
  },
  setProduct(state, product) {
    state.product = product;
  },
  setToggleModal(state, status) {
    state.isOpen = status;
  },
  setNutritionist(state, nutritionist) {
    state.nutritionist = nutritionist;
  },
  setKuisioner(data, kuisioner) {
    state.kuisioner = kuisioner;
  },
  setSuccessPayload(state, data) {
    state.successPayload = data
  },
  setNotifications(state, notifications) {
    state.notifications = notifications
  },
};

export const actions = {
  getClientProfileById(ctx, clientId) {
    let axios = ctx.rootGetters.axios;
    return axios
      .get(`/v1/nutritionist/clients/${clientId}`)
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit("setClients", [it]);
        return it
      });
  },
  getClientById(ctx, clientId) {
    let axios = ctx.rootGetters.axios;
    return axios
      .get(`/v1/nutritionist/diet-data/${clientId}`)
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit("setClients", [it]);
      });
  },
  listClient(ctx, props) {
    let axios = ctx.rootGetters.axios;
    let perPage = ctx.state.perPage;
    let page = props?.page ?? 1;
    let query = props?.query;

    let params = new URLSearchParams();
    params.set("perpage", perPage);
    params.set("page", page);
    if (query != null) params.set("query", "");

    return axios
      .get(`/v1/nutritionist/clients?${params.toString()}`)
      .then((it) => it.data)
      .then((it) => {
        ctx.commit("setClients", it.data);
        ctx.commit("setTotalPage", it.meta.total);
        ctx.commit("setCurrentPage", it.meta.page);
      });
  },
  getRencanaMakan(ctx, programId) {
    let axios = ctx.rootGetters.axios;
    return axios
      .get(`/v1/clients/meal-plans/${programId}`)
      .then((it) => it.data)
      .then((it) => it.data)
      .then((it) => {
        ctx.commit("setRencanaMakan", it);
        return it;
      });
  },
  getRekomendasiMenu(ctx, { programId, day }) {
    let axios = ctx.rootGetters.axios;
    return axios
      .get(`/v1/clients/meals-recommendation/${programId}/${day}`)
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit("setRekomendasiMenu", it);
        return it;
      });
  },
  getProfileGizi(ctx, programId) {
    let axios = ctx.rootGetters.axios;
    let url = programId == null
      ? `/v1/clients/nutrition-profile`
      : `/v1/clients/nutrition-profile/${programId}`;
    return axios
      .get(url)
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit("setProfileGizi", it);
      });
  },
  getAsupanGizi(ctx, programId) {
    let axios = ctx.rootGetters.axios;
    let url = programId == null ? `/v1/clients/nutrition-intake` : `/v1/clients/nutrition-intake/${programId}`;
    return axios
      .get(url)
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit("setAsupanGizi", it);
      });
  },
  getKondisiTubuh(ctx, programId) {
    let axios = ctx.rootGetters.axios;
    let url = programId == null
      ? `/v1/clients/body-proportion-lifestyle`
      : `/v1/clients/body-proportion-lifestyle/${programId}`;

    return axios
      .get(url)
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit("setKondisiTubuh", it);
      });
  },
  getSaran(ctx, programId) {
    let axios = ctx.rootGetters.axios;
    let url = programId == null
      ? `/v1/clients/nutrition-lifestyle-advice`
      : `/v1/clients/nutrition-lifestyle-advice/${programId}`;

    return axios
      .get(url)
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit("setSaran", it);
      })
      .catch(() => { });
  },
  getProducts(ctx) {
    let axios = ctx.rootGetters.axios;
    return axios
      .get(`/v1/general/products`)
      .then((it) => it.data)
      .then((it) => it.data)
      .then((it) => {
        ctx.commit("setProducts", it);
      });
  },
  getProductById(ctx, id) {
    let axios = ctx.rootGetters.axios;
    return axios
      .get(`/v1/general/products/${id}`)
      .then((it) => it.data)
      .then((it) => it.data)
      .then((it) => {
        ctx.commit("setProduct", it);
        return it
      });
  },
  onToggleModal(ctx, status) {
    ctx.commit("setToggleModal", status);
  },
  addToCart(ctx, product) {
    let axios = ctx.rootGetters.axios;
    return axios
      .post(`/v1/clients/carts`, product)
      .then((r) => r.data.data)
      .then((r) => {
        console.log("@add-to-cart.create", r);
        return r;
      });
  },
  async getKuisioner(ctx, programId) {
    let axios = ctx.rootGetters.axios;
    return axios
      .get(`/v1/clients/quizioner/${programId}`)
      .then((it) => it.data.data)
      .then((data) => {
        ctx.commit("setKuisioner", data);
        return data;
      });
  },
  getNutritionistById(ctx, nutritionistId) {
    let axios = ctx.rootGetters.axios;
    return axios
      .get(`/v1/clients/nutritionist/${nutritionistId}`)
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit("setNutritionist", it);
      });
  },
  updateSuccessPayload(ctx, data) {
    ctx.commit("setSuccessPayload", data)
  },
  async loadNotifications(ctx) {
    let axios = ctx.rootGetters.axios;
    return axios
      .get("/v1/users/notifications")
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit("setNotifications", it);
      }).catch((_) => { });
  },
  chooseNutritionist(ctx, data) {
    let axios = ctx.rootGetters.axios;
    return axios
      .post(`/v1/clients/programs/${data.programId}/choose-nutritionist`, data.nutritionist)
      .then((it) => it.data.data)
      .then((it) => {
        return it;
      })
      .catch((err) => {
        throw err.response;
      });
  },
  readNotification(ctx, id) {
    let axios = ctx.rootGetters.axios;
    let notificationId = id;
    return axios
      .put(`v1/users/notifications/${id}/read`)
      .then((it) => it.data.data)
      .then((it) => {
        let notifications = ctx.getters.notifications
        let idxNotification = notifications.findIndex((it) => it.id == notificationId)
        if (idxNotification > -1) {
          notifications[idxNotification].isRead = 1
        }
        ctx.commit("setNotifications", notifications);
      })
      .catch((err) => {
        console.error(err.response);
      });
  },
};
