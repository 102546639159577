export const namespaced = true
export const state = () => ({
  history: [],
});

export const getters = {
  history: state => state.history,
};

export const mutations = {
  setHistory(state, programs) {
    state.history = programs
  },
};

export const actions = {
  listHistoryPrograms(ctx) {
    let axios = ctx.rootGetters.axios;
    return axios
      .get(`/v1/clients/programs`)
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit("setHistory", it);
        return it;
      });
  },
};
