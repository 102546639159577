export const namespaced = true;

export const state = {
    historyAdimeNotes: [],
    reminderAdimeNote: {},
    adimeNote: {},
};

export const getters = {};

export const mutations = {
    setHistory(state, histories) {
        state.historyAdimeNotes = histories;
    },
    setReminder(state, reminder) {
        state.reminderAdimeNote = reminder;
    },
    setAdimeNote(state, adimeNote) {
        state.adimeNote = adimeNote;
    },
    setDraftAdimeNote(state, draft) {
        if (draft.dataValues) {
            state.adimeNote = Object.assign(draft.dataValues, draft)
        } else {
            state.adimeNote = Object.assign({...state.adimeNote}, draft)
        }
    }
};

export const actions = {
    async getAdimeNotesByClientId(ctx, clientId) {
        const axios = ctx.rootGetters.axios;
        const resp = await axios.get(`/v1/nutritionist/clients/${clientId}/adime-notes`).then(r => r.data);
        const data = resp.data;
        ctx.commit("setHistory", data);
        return data;
    },
    async getAdimeNotesDraft(ctx, clientId) {
        const axios = ctx.rootGetters.axios;
        const resp = await axios.get(`/v1/nutritionist/clients/${clientId}/adime-notes/draft`).then(r => r.data);
        const data = resp.data;
        ctx.commit("setDraftAdimeNote", data);
        return data;
    },
    async getAdimeNotesReminder(ctx, clientId) {
        const axios = ctx.rootGetters.axios;
        const resp = await axios.get(`/v1/nutritionist/clients/${clientId}/adime-notes-reminder`).then(r => r.data);
        const data = resp.data;
        ctx.commit("setReminder", data);
        return data;
    },
    async getAdimeNotesById(ctx, id) {
        const axios = ctx.rootGetters.axios;
        const resp = await axios.get(`/v1/nutritionist/adime-notes/${id}`).then(r => r.data);
        const data = resp.data;
        ctx.commit("setAdimeNote", data);
        return data;
    },
    setAdimeNotes(ctx, { clientId, params }) {
        const axios = ctx.rootGetters.axios;
        return axios
            .post(`/v1/nutritionist/clients/${clientId}/adime-notes`, params)
            .then((it) => it.data.data)
            .then((it) => {
                return it;
            })
            .catch((err) => {
                throw err.response;
            });
    },
    updateAdimeNotes(ctx, { clientId, params }) {
        const axios = ctx.rootGetters.axios;
        return axios
            .put(`/v1/nutritionist/clients/${clientId}/adime-notes`, params)
            .then((it) => it.data.data)
            .then((it) => {
                return it;
            })
            .catch((err) => {
                throw err.response;
            });
    },
};
