export const namespaced = true

export const state = {
  items: [],
  item: {},
  meta: {},
}

export const getters = {
  items: (state) => state.items,
  item: (state) => state.item,
  meta: (state) => state.meta,
}

export const mutations = {
  setItems(state, items) {
    state.items = items;
  },
  setItem(state, item) {
    state.item = item;
  },
  setMeta(state, meta) {
    state.meta = meta
  },
}

export const actions = {
  async listCouponsAdmin(ctx, params) {
    let axios = ctx.rootGetters.axios;
    return axios
      .get(`/v1/admin/coupons`, {params: params})
      .then((it) => it.data)
      .then((it) => {
        ctx.commit("setItems", it.data);
        ctx.commit("setMeta", it.meta);
      })
      .catch(() => {
        ctx.commit("setItems", []);
        ctx.commit("setMeta", 1);
      });
  },
  createCouponsAdmin(ctx, data) {
    let axios = ctx.rootGetters.axios;
    return axios
      .post(`/v1/admin/coupons`, data)
      .then((it) => it.data.data)
      .then((it) => {
        return it;
      })
      .catch((err) => {
        throw err.response;
      });
  },
  detailCouponsAdmin(ctx, id) {
    let axios = ctx.rootGetters.axios;
    return axios
      .get(`/v1/admin/coupons/${id}`)
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit("setItem", it);
      })
      .catch((err) => {
        throw err.response;
      });
  },
  deleteCouponsAdmin(ctx, id) {
    let axios = ctx.rootGetters.axios;
    return axios
      .delete(`/v1/admin/coupons/${id}`)
      .then((it) => it.data.data)
      .then((it) => {
        console.log("removed", it);
      })
      .catch((err) => {
        throw err.response;
      });
  },
  updateCouponsAdmin(ctx, data) {
    let axios = ctx.rootGetters.axios;
    return axios
      .put(`/v1/admin/coupons/${data.id}`, data)
      .then((it) => it.data.data)
      .then((it) => {
        console.log("updated", it);
      })
      .catch((err) => {
        throw err.response;
      });
  },
}
