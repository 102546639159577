export const namespaced = true

export const state = {
  items: [],
  item: {},
  meta: {},
  clientItems: [],
}

export const getters = {
  items: (state) => state.items,
  item: (state) => state.item,
  meta: (state) => state.meta,
  clientItems: (state) => state.clientItems,
  universities: (state) => state.universities,
}

export const mutations = {
  setItems(state, items) {
    state.items = items;
  },
  setItem(state, item) {
    state.item = item;
  },
  setMeta(state, meta) {
    state.meta = meta;
  },
  setClientItems(state, clients) {
    state.clientItems = clients;
  },
}

export const actions = {
  async listNutritionists(ctx, params) {
    let axios = ctx.rootGetters.axios;
    return axios
      .get(`/v1/super-admin/nutritionist`, { params: params })
      .then((it) => it.data)
      .then((it) => {
        ctx.commit("setItems", it.data);
        ctx.commit("setMeta", it.meta);
      })
      .catch(() => {
        ctx.commit("setItems", []);
        ctx.commit("setMeta", 1);
      });
  },
  createNutritionists(ctx, data) {
    let axios = ctx.rootGetters.axios;
    return axios
      .post(`/v1/super-admin/nutritionist`, data)
      .then((it) => it.data.data)
      .then((it) => {
        return it;
      })
      .catch((err) => {
        throw err.response;
      });
  },
  detailNutritionists(ctx, id) {
    let axios = ctx.rootGetters.axios;
    return axios
      .get(`/v1/super-admin/nutritionist/${id}`)
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit("setItem", it);
      })
      .catch((err) => {
        throw err.response;
      });
  },
  nutritionistsClients(ctx, id, client) {
    let axios = ctx.rootGetters.axios;
    return axios
      .get(`/v1/super-admin/nutritionist/${id}/clients`, { params: client })
      .then((it) => it.data)
      .then((it) => {
        ctx.commit("setClientItems", it.data);
        ctx.commit("setMeta", it.meta);
      })
      .catch((err) => {
        throw err.response;
      });
  },
  deleteNutritionists(ctx, id) {
    let axios = ctx.rootGetters.axios;
    return axios
      .delete(`/v1/super-admin/nutritionist/${id}`)
      .then((it) => it.data.data)
      .then((it) => {
        console.log("removed", it);
      })
      .catch((err) => {
        throw err.response;
      });
  },
  updateNutritionists(ctx, data) {
    let axios = ctx.rootGetters.axios;
    return axios
      .put(`/v1/super-admin/nutritionist/${data.id}`, data)
      .then((it) => it.data.data)
      .then((it) => {
        console.log("updated", it);
      })
      .catch((err) => {
        throw err.response;
      });
  },
  exportFileNutritionists(ctx) {
    let axios = ctx.rootGetters.axios;
    return axios
      .get(`/v1/super-admin/nutritionist/export?token=${ctx.rootState.auth.token}`, { responseType: 'blob' })
      .then((it) => it.data)
      .then((it) => it)
      .catch((err) => {
        throw err.response;
      });
  },
}
