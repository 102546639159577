export const namespaced = true;

export const state = () => ({
  user: null,
  token: null,
  refreshToken: null,
});

export const getters = {
  isAuthenticated: (state) => !!state.token,
  fullName: s => `${s.user?.firstName} ${s.user?.lastName}`
};

export const mutations = {
  setUser(state, user) {
    state.user = user
  },
  setToken(state, token) {
    state.token = token
  },
  setRefreshToken(state, token) {
    state.refreshToken = token
  },
};

export const actions = {
  /**
   * @param {{
   *     firstName: string,
   *     lastName: string,
   *     birthPlace: string,
   *     birthDate: string,
   *     address: string,
   *     city: string,
   *     zipCode: number,
   *     phone: string,
   *     country: string,
   *     email: string,
   *     password: string,
   *     role: string,
   * }} data
   * birthDate in `yyyy-mm-dd` format
   *
   * @returns {Promise<void>}
   */
  async signup(ctx, data) {
    let axios = ctx.rootGetters.axios;

    data.birthPlace = 'Somewhere';
    data.role = 'client';

    return axios
      .post('/v1/users/register', data)
      .then((it) => it.data)
      .catch((err) => {
        let message = err.response.data.message;
        let messages = message
          ?.split('\n')
          .map((it) => it.includes(':') ? it.split(':')[1] : it)
          .map((it) => it?.trim())
          .filter((it) => it != null && it !== '');
        throw messages;
      });
  },
  async signin(ctx, { email, password }) {
    let axios = ctx.rootGetters.axios;
    return axios
      .post('/v1/auth/login', { email, password })
      .then((it) => it.data)
      .then((it) => {
        let { token, refreshToken, ...user } = it.data
        ctx.commit('setUser', user)
        ctx.commit('setToken', token)
        ctx.commit('setRefreshToken', refreshToken)

        return user;
      })
      .catch((err) => {
        let message = err.response.data.message;
        throw [message];
      });
  },
  async signout(ctx) {
    let axios = ctx.rootGetters.axios;
    return axios.post('/v1/auth/logout')
      .then(() => {
        ctx.commit('setUser', null)
        ctx.commit('setToken', null)
      });
  },
  async refreshToken(ctx) {
    let axios = ctx.rootGetters.axios;
    let { refreshToken } = ctx.state;

    return axios.post(`/v1/auth/refresh-token`, {
      userId: ctx.state.user.id,
      refreshToken,
    }).then(it => it.data.data)
      .then(it => {
        ctx.commit('setToken', it.token)
        return it.token;
      })
  },
  forgotPassword(ctx, email) {
    let axios = ctx.rootGetters.axios;
    return axios.put(`/v1/auth/reset-password-email`, email)
      .then(it => it.data.data)
      .then(it => {
        return it;
      })
      .catch((err) => {
        throw err.response;
      });
  },
  resendVerification(ctx, email) {
    let axios = ctx.rootGetters.axios;
    return axios.put(`/v1/users/verify-email`, email)
      .then(it => it.data.data)
      .then(it => {
        return it;
      })
      .catch((err) => {
        throw err.response;
      });
  },
};
