export const namespaced = true

export const state = {
  items: [],
  item: {},
  meta: {},
  types: [
    { value: 'daily_coaching', label: "Daily Coaching" },
    { value: 'coaching_course', label: "Coaching Course" },
    { value: 'diet_challenge', label: "Diet Challenge" },
  ],
  category: [
    { value: '', label: "Semua" },
    { value: 'healthy_mind', label: "Healthy Mind" },
    { value: 'healthy_diet', label: "Healthy Diet" },
    { value: 'healthy_body', label: "Healthy Body" },
  ],
  categoryCoaching: [
    { value: "", label: "Semua" },
    { value: "diet_anak", label: "Diet anak" },
    { value: "diet_remaja", label: "Diet remaja" },
    { value: "diet_dewasa", label: "Diet dewasa" },
    { value: "diet_lansia", label: "Diet lansia" },
    { value: "diet_plant-based", label: "Diet plant-based" },
    { value: "manajemen_berat_badan_komposisi_tubuh", label: "Manajemen berat badan & komposisi tubuh" },
    { value: "gangguan_makan", label: "Gangguan makan (disordered eating)" },
    { value: "gizi_olahraga", label: "Gizi olahraga" },
    { value: "PCOS", label: "PCOS" },
    { value: "persiapan_kehamilan", label: "Persiapan kehamilan" },
    { value: "MP-ASI", label: "MP-ASI" },
    { value: "asam_urat", label: "Asam urat" },
    { value: "diabetes_mellitus", label: "Diabetes mellitus" },
    { value: "kolesterol", label: "Kolesterol" },
    { value: "hipertensi", label: "Hipertensi" },
    { value: "kanker", label: "Kanker" },
    { value: "penyakit_jantung", label: "Penyakit jantung" },
    { value: "penyakit_hati", label: "Penyakit hati" },
    { value: "penyakit_ginjal", label: "Penyakit ginjal" },
    { value: "lainnya", label: "Lainnya" },
  ],
  labelCategory: '',
}

export const getters = {
  items: (state) => state.items,
  item: (state) => state.item,
  meta: (state) => state.meta,
  category: (state) => state.category,
  categoryCoaching: (state) => state.categoryCoaching,
  labelCategory: (state) => state.labelCategory,
}

export const mutations = {
  setItems(state, items) {
    state.items = items;
  },
  setItem(state, item) {
    state.item = item;
  },
  setMeta(state, meta) {
    state.meta = meta
  },
  setLabelCategory(state, data) {
    if (data.type == 'coaching_course') {
      state.labelCategory = state.categoryCoaching.find((dt) => dt.value === data.category)?.label;
    } else {
      state.labelCategory = state.category.find((dt) => dt.value === data.category)?.label;
    }
  },
}

export const actions = {
  // client
  async listContentChallengeClient(ctx, params) {
    let axios = ctx.rootGetters.axios;
    return axios
      .get(`/v1/clients/content-and-challenge`, { params: params })
      .then((it) => it.data)
      .then((it) => {
        ctx.commit("setItems", it.data);
        ctx.commit("setMeta", it.meta);
      });
  },
  detailContentChallengeClient(ctx, id) {
    let axios = ctx.rootGetters.axios;
    return axios
      .get(`/v1/clients/content-and-challenge/${id}`)
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit("setItem", it);
      })
      .catch((err) => {
        throw err.response;
      });
  },
  getLabelCategory(ctx, item) {
    ctx.commit("setLabelCategory", { type: item.type, category: item.category });
  },

  // nutritionist
  async listContentChallengeNutritionist(ctx, params) {
    let axios = ctx.rootGetters.axios;
    return axios
      .get(`/v1/nutritionist/content-and-challenge`, { params: params })
      .then((it) => it.data)
      .then((it) => {
        ctx.commit("setItems", it.data);
        ctx.commit("setMeta", it.meta);
      });
  },
  detailContentChallengeNutritionist(ctx, id) {
    let axios = ctx.rootGetters.axios;
    return axios
      .get(`/v1/nutritionist/content-and-challenge/${id}`)
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit("setItem", it);
      })
      .catch((err) => {
        throw err.response;
      });
  },

  // superadmin
  async listContentChallengeSuperadmin(ctx, params) {
    let axios = ctx.rootGetters.axios;
    return axios
      .get(`/v1/super-admin/content-and-challenge`, { params: params })
      .then((it) => it.data)
      .then((it) => {
        ctx.commit("setItems", it.data.rows);
        ctx.commit("setMeta", it.meta);
      })
      .catch(() => {
        ctx.commit("setItems", []);
        ctx.commit("setMeta", 1);
      });
  },
  createContentChallengeSuperadmin(ctx, data) {
    let axios = ctx.rootGetters.axios;
    return axios
      .post(`/v1/super-admin/content-and-challenge`, data)
      .then((it) => it.data.data)
      .then((it) => {
        return it;
      })
      .catch((err) => {
        throw err.response;
      });
  },
  detailContentChallengeSuperadmin(ctx, id) {
    let axios = ctx.rootGetters.axios;
    return axios
      .get(`/v1/super-admin/content-and-challenge/${id}`)
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit("setItem", it);
      })
      .catch((err) => {
        throw err.response;
      });
  },
  deleteContentChallengeSuperadmin(ctx, id) {
    let axios = ctx.rootGetters.axios;
    return axios
      .delete(`/v1/super-admin/content-and-challenge/${id}`)
      .then((it) => it.data.data)
      .then((it) => {
        console.log("removed", it);
      })
      .catch((err) => {
        throw err.response;
      });
  },
  updateContentChallengeSuperadmin(ctx, data) {
    let axios = ctx.rootGetters.axios;
    return axios
      .put(`/v1/super-admin/content-and-challenge/${data.id}`, data)
      .then((it) => it.data.data)
      .then((it) => {
        console.log("updated", it);
      })
      .catch((err) => {
        throw err.response;
      });
  },


  // superadmin
  async listContentChallengeAdmin(ctx, params) {
    let axios = ctx.rootGetters.axios;
    return axios
      .get(`/v1/admin/content-and-challenge`, { params: params })
      .then((it) => it.data)
      .then((it) => {
        ctx.commit("setItems", it.data.rows);
        ctx.commit("setMeta", it.meta);
      })
      .catch(() => {
        ctx.commit("setItems", []);
        ctx.commit("setMeta", 1);
      });
  },
  createContentChallengeAdmin(ctx, data) {
    let axios = ctx.rootGetters.axios;
    return axios
      .post(`/v1/admin/content-and-challenge`, data)
      .then((it) => it.data.data)
      .then((it) => {
        return it;
      })
      .catch((err) => {
        throw err.response;
      });
  },
  detailContentChallengeAdmin(ctx, id) {
    let axios = ctx.rootGetters.axios;
    return axios
      .get(`/v1/admin/content-and-challenge/${id}`)
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit("setItem", it);
      })
      .catch((err) => {
        throw err.response;
      });
  },
  deleteContentChallengeAdmin(ctx, id) {
    let axios = ctx.rootGetters.axios;
    return axios
      .delete(`/v1/admin/content-and-challenge/${id}`)
      .then((it) => it.data.data)
      .then((it) => {
        console.log("removed", it);
      })
      .catch((err) => {
        throw err.response;
      });
  },
  updateContentChallengeAdmin(ctx, data) {
    let axios = ctx.rootGetters.axios;
    return axios
      .put(`/v1/admin/content-and-challenge/${data.id}`, data)
      .then((it) => it.data.data)
      .then((it) => {
        console.log("updated", it);
      })
      .catch((err) => {
        throw err.response;
      });
  },
}
