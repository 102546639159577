export const namespaced = true
export const state = {
  kuisioner: [
    { id: 1, name: 'Kuisioner 1' },
    { id: 2, name: 'Kuisioner 2' },
    { id: 3, name: 'Kuisioner 3' },
    { id: 4, name: 'Kuisioner 4' },
    { id: 5, name: 'Kuisioner 5' },
    { id: 6, name: 'Kuisioner 6' },
    { id: 7, name: 'Kuisioner 7' },
    { id: 8, name: 'Kuisioner 8' },
  ],
  mealPlan: [
    { id: 1, name: 'Meal Plan 1' },
    { id: 2, name: 'Meal Plan 2' },
    { id: 3, name: 'Meal Plan 3' },
    { id: 4, name: 'Meal Plan 4' },
    { id: 5, name: 'Meal Plan 5' },
    { id: 6, name: 'Meal Plan 6' },
    { id: 7, name: 'Meal Plan 7' },
  ],
  progressTracker: [
    { id: 1, name: 'Progress Tracker 1' },
    { id: 2, name: 'Progress Tracker 2' },
    { id: 3, name: 'Progress Tracker 3' },
    { id: 4, name: 'Progress Tracker 4' },
    { id: 5, name: 'Progress Tracker 5' },
    { id: 6, name: 'Progress Tracker 6' },
    { id: 7, name: 'Progress Tracker 7' },
    { id: 8, name: 'Progress Tracker 8' },
    { id: 9, name: 'Progress Tracker 9' },
  ],
}
